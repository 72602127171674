<script setup>
import { ref, onMounted, compile, computed } from "vue";
import { useUserStore } from "@/stores/userStore";
import router from "@/router";
import {
  getUserServiceHistory,
  postUserServiceCancel,
  getUserServiceCancelHistory,
} from "@/api/request";
import { formatDateFullString, sendErrorLogToSentry } from "@/utils/utils";
import { useLayoutStore } from "@/stores/layoutStore";
import CancelMbDataInputPopup from "@/components/Popup/CancelMbDataInputPopup.vue";
import { PaymentMethod } from "@/type/paymentMethod";

const userStore = useUserStore();
const layoutStore = useLayoutStore();

const isHistoryMode = ref(true);

const historyList = ref([]);
const cancelHistoryList = ref([]);
const viewHistoryList = computed(() =>
  isHistoryMode.value ? historyList.value : cancelHistoryList.value
);

const showCancelMbPopup = ref(false);
const selectedCancelItem = ref({});
const closeCancelMbPoup = () => {
  selectedCancelItem.value = {};
  showCancelMbPopup.value = false;
};

// function
function routerToLogin() {
  router.push("/login");
}

function routerToProfile() {
  router.replace("/my/profile");
}

function onClickProfileButton() {
  routerToProfile();
}

function chnageHistoryMode(isHistory) {
  isHistoryMode.value = isHistory;
  if (isHistory) {
    reqeustUserHistoryList();
  } else {
    requestUserCancelHistory();
  }
}

function canCancel(disposeDateString) {
  const disposeDate = new Date(disposeDateString);
  disposeDate.setHours(0, 0, 0, 0);

  // 현재 날짜 및 시간을 구함
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const cutoffTime = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    15,
    30,
    0
  );

  if (disposeDate.getTime() === today.getTime()) {
    if (now.getTime() <= cutoffTime.getTime()) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}

function shouldShowAccountModal(item) {
  if (
    item.payment_method === PaymentMethod.MANUAL_BANK &&
    item.dispose_no != null
  ) {
    return true;
  } else {
    return false;
  }
}

function cancelMbResultAction(isSuccess) {
  if (isSuccess) {
    alert("취소가 완료되었습니다.");
    reqeustUserHistoryList();
  } else {
    alert("취소가 정상적으로 처리되지 않았습니다.");
  }
}

function onClickCancelButton(item) {
  if (item.bulk_cancelable) {
    if (canCancel(item.dispose_date)) {
      if (shouldShowAccountModal(item)) {
        selectedCancelItem.value = item;
        showCancelMbPopup.value = true;
        return;
      }

      if (confirm("해당 배출건을 취소하시겠습니까?")) {
        requestUserServiceCancel(item.id, item.service_type);
      }
    } else {
      alert("배출 당일 15:30 이후 취소는 고객센터로 문의해 주세요.");
    }
  }
}

// api request

async function reqeustUserHistoryList() {
  try {
    const response = await getUserServiceHistory();
    historyList.value = [];

    const groupedData = response.reduce((acc, currentValue) => {
      if (currentValue.service_type === "blw_dispose_report") {
        const date = currentValue.registered_at; //.split(" ")[0].replace(/-/g, "-");

        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(currentValue);
      }

      return acc;
    }, {});

    historyList.value = groupedData;
  } catch (error) {
    sendErrorLogToSentry(error);
  }
}

async function requestUserCancelHistory() {
  try {
    const response = await getUserServiceCancelHistory();
    cancelHistoryList.value = [];

    const groupedData = response.reduce((acc, currentValue) => {
      if (currentValue.service_type === "blw_dispose_report") {
        const date = currentValue.registered_at; //.split(" ")[0].replace(/-/g, "-");

        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(currentValue);
      }

      return acc;
    }, {});

    cancelHistoryList.value = groupedData;
  } catch (error) {
    sendErrorLogToSentry(error);
  }
}

async function requestUserServiceCancel(id, serviceType) {
  try {
    const response = await postUserServiceCancel(id, serviceType);

    alert("취소가 완료되었습니다.");
    reqeustUserHistoryList();
  } catch (error) {
    alert("취소가 정상적으로 처리되지 않았습니다.");
    sendErrorLogToSentry(error);
  }
}

// life cycle

onMounted(() => {
  const access = userStore.access;
  const userId = userStore.loginUserI;
  if (access === null || userId === null || userId === "") {
    alert("로그인 이후 사용할수 있는 기능 입니다.");
    routerToLogin();
  } else {
    reqeustUserHistoryList();
  }
});
</script>

<template>
  <div id="pc_history">
    <cancel-mb-data-input-popup
      :cancel-item="selectedCancelItem"
      :is-show="showCancelMbPopup"
      @close-alert="closeCancelMbPoup"
      @api-result-action="cancelMbResultAction"
    ></cancel-mb-data-input-popup>
    <div class="page_container flex flex_col justify_start align_center">
      <div
        class="single-content-page-wrap flex flex_col justify_center align_start"
      >
        <!-- 제목 Tab (PC) -->
        <div
          class="title_wrap flex flex_row justify_start align_center only_pc_flex"
        >
          <span class="head_2 extra_bold black"> 마이페이지 </span>
        </div>
        <div
          class="flex flex_row align_center justify_space_between only_pc_flex"
          style="width: 100%; margin-bottom: 60px; flex-wrap: wrap"
        >
          <span class="subtitle_1 color_primary" style="max-width: 100%">
            {{ userStore.loginUserName }}
            <span class="subtitle_2 color_blue_midnight"> &nbsp님 </span>
          </span>
          <div class="change_myInfo_wrap flex flex_row align_end justify_end">
            <img src="@/assets/icon/ic_settings.svg" />
            <button @click="onClickProfileButton" class="body_2 color_caption">
              내 정보 변경
            </button>
          </div>
        </div>

        <!-- 제목 Tab (PC) -->
        <div class="only_mo_block" style="width: 100%">
          <div
            class="title_wrap flex flex_row justify_start align_center justify_space_between"
          >
            <span class="head_2 extra_bold black"> 마이페이지 </span>
            <div class="change_myInfo_wrap flex flex_row align_end justify_end">
              <img src="@/assets/icon/ic_settings.svg" />
              <button
                @click="onClickProfileButton"
                class="body_2 color_caption"
              >
                내 정보 변경
              </button>
            </div>
          </div>
        </div>

        <div class="flex flex_row mb-10" style="width: 100%">
          <div
            @click="() => chnageHistoryMode(true)"
            class="flex flex_col"
            style="width: 50%; cursor: pointe"
          >
            <span
              class="sub_title extra_bold center"
              :class="isHistoryMode ? 'black3' : 'gray4'"
              style="margin-bottom: 10px"
            >
              신청내역
            </span>
            <div
              :class="
                isHistoryMode ? 'enable_title_divider' : 'disable_title_divider'
              "
            ></div>
          </div>
          <div
            @click="() => chnageHistoryMode(false)"
            class="flex flex_col"
            style="width: 50%; cursor: pointer"
          >
            <span
              class="sub_title extra_bold center"
              :class="isHistoryMode ? 'gray4' : 'black3'"
              style="margin-bottom: 10px"
            >
              취소내역
            </span>
            <div
              :class="
                isHistoryMode ? 'disable_title_divider' : 'enable_title_divider'
              "
            ></div>
          </div>
        </div>
        <!-- <div class="title_wrap flex flex_row justify_start align_center">
          <button
            @click="onClickProfileButton"
            class="text_50 extra_bold left page_title gray4"
          >
            내 정보
          </button>
          <button class="text_50 extra_bold left page_title">신청내역</button>
        </div>

        검색창
        <div class="input_wrap flex flex_row justify_center align_center">
          <input
            class="input grow"
            type="text"
            name="searchValue"
            id="searchValue"
            placeholder="이전 신청내역을 검색해 보세요."
          />
          <button
            id="search"
            class="bg_primary flex justify_center align_center"
          >
            <img src="@/assets/icon/ic_search.svg" alt="검색버튼" />
          </button>
        </div> -->

        <!-- 기간 선택 -->
        <!-- <div class="tag_wrap flex flex_row justify_start align_center">
          <span class="tag mid flex justify_center align_center">오늘</span>
          <span class="tag active mid flex justify_center align_center"
            >1주일</span
          >
          <span class="tag mid flex justify_center align_center">1개월</span>
          <span class="tag mid flex justify_center align_center">6개월</span>
          <span class="tag mid flex justify_center align_center">2022</span>
          <span class="tag mid flex justify_center align_center">2021</span>
        </div> -->

        <!-- 검색 내역 -->
        <!-- <div class="title_divider mb-10"></div> -->
        <div class="result_container flex flex_col justify_start align_start">
          <div
            v-for="(group, index) in viewHistoryList"
            :id="index + '_wrap'"
            class="result_wrap"
          >
            <p class="result_title date black3 bold left">
              {{ formatDateFullString(index) }}
            </p>
            <div class="result_list flex flex_col justify_start align_start">
              <div
                v-for="item in group"
                class="result_item flex flex_col justify_space_between"
              >
                <div class="blw_title_bar">
                  <span class="subtitle_6 color_secondary" style="display: flex; align-items: center; gap: 4px;">
                    대형 생활 폐기물
                    <img src="@/assets/icon/ic_ellipse.svg" />
                    <span class="subtitle_5 color_secondary"> 배출신고</span>
                  </span>
                </div>
                <div class="blw_content_wrap">
                  <div class="flex flex_col align_start">
                    <div class="sub_description mb-1">배출번호</div>
                    <span class="subtitle_7 primary left">
                      {{ item.dispose_no === null ? "-" : item.dispose_no }}
                    </span>
                  </div>
                  <div class="title_divider"></div>
                  <div class="sub_description mb-1">배출품목</div>
                  <div class="flex flex_row justify_space_between align_end">
                    <div class="grow flex flex_row justify_start align_center">
                      <div class="grow">
                        <template v-for="(categoryItem, idx) in item.item_list">
                          <div
                            class="flex flex_row justify_space_between only_pc_flex"
                          >
                            <p
                              class="subtitle_7 color_secondary bold left result_item_title"
                            >
                              {{ categoryItem.category_info }}
                            </p>
                            <span
                              class="subtitle_7 color_secondary bold left result_item_title"
                            >
                              {{ categoryItem.quantity }} 개</span
                            >
                          </div>
                          <p
                            class="subtitle_7 color_secondary bold left result_item_title only_mo_block"
                          >
                            {{ categoryItem.category_info }}
                          </p>
                          <p class="text_12 gray2 left mb-1">
                            {{ categoryItem.standard_name }}
                          </p>
                          <span
                            class="subtitle_7 color_secondary bold left result_item_title only_mo_block"
                          >
                            {{ categoryItem.quantity }} 개</span
                          >
                          <div
                            v-show="
                              layoutStore.isMobile &&
                              idx !== item.item_list.length - 1
                            "
                            class="item_divider"
                          ></div>
                        </template>
                        <div class="flex flex_col">
                          <div class="title_divider"></div>
                          <div class="sub_description mb-1">배출일자</div>
                          <div
                            class="subtitle_7 color_secondary"
                            style="margin-bottom: 10px"
                          >
                            {{ item.dispose_date }}
                          </div>
                        </div>
                        <template v-if="isHistoryMode">
                          <div class="flex flex_col">
                            <button
                              @click="() => onClickCancelButton(item)"
                              v-bind:class="[
                                item.bulk_cancelable
                                  ? 'cancel_button_active'
                                  : 'cancel_button',
                              ]"
                              class="subtitle_7 color_secondary"
                              :disabled="!item.bulk_cancelable"
                            >
                              취소하기
                            </button>
                            <span
                              class="body_3 color_warning center"
                              style="margin-top: 10px; width: 100%"
                            >
                              배출 당일 15:30 이후 취소는 고객센터로 문의해
                              주세요.
                            </span>
                          </div>
                        </template>
                        <template v-else>
                          <button
                            class="subtitle_7 color_secondary cancel_button_complete"
                            disabled
                          >
                            취소완료
                          </button>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#pc_history .title_wrap {
  gap: 30px;
  margin-bottom: 60px;
}
#pc_history .change_myInfo_wrap {
  margin-left: auto;
  align-items: center;
}

#pc_history .change_myInfo_wrap img {
  width: 18px;
  height: 18px;
  margin-right: 4px;
  -webkit-filter: invert(59%) sepia(49%) saturate(205%) hue-rotate(177deg)
    brightness(87%) contrast(87%);
  filter: invert(59%) sepia(49%) saturate(205%) hue-rotate(177deg)
    brightness(87%) contrast(87%);
}

#pc_history .sub_title {
  font-size: 28px;
}

#pc_history .title_divider {
  height: 1px;
  width: 100%;
  background: #e7ecf4;
  margin-top: 10px;
}

#pc_history .date {
  font-size: 14px;
  line-height: 21px;
  font-weight: 800;
}

#pc_history .blw_title_bar {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #e7ecf4;
  height: 44px;
  padding: 8px 28px 8px 28px;
}

#pc_history .blw_title_bar img {
  width: 3px;
  height: 3px;
  vertical-align: middle;
}

#pc_history .input_wrap {
  width: 100%;
  gap: 0px;
  margin-bottom: 20px;
  position: relative;
}
#pc_history .input_wrap input {
  border: 1px solid #1e64ff;
  padding-right: 54px;
}
#pc_history .input_wrap button#search {
  position: absolute;
  top: 0;
  right: 0;
  width: 42px;
  height: 42px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
#pc_history .input_wrap button#search img {
  width: 21px;
  height: auto;
}

#pc_history .tag_wrap {
  margin-bottom: 30px;
  gap: 10px;
}

#pc_history .result_container {
  width: 100%;
  gap: 40px;
}
.result_container .result_wrap {
  width: 100%;
}
.result_wrap .result_title {
  margin-bottom: 10px;
}

.result_wrap .result_list {
  gap: 20px;
}
.result_list .result_item {
  width: 100%;

  border: 1px solid #e7ecf4;
  border-radius: 6px;
  cursor: pointer;
}

.result_list .sub_description {
  font-size: 14px;
  color: #7c8fac;
  font-weight: 400;
  margin-top: 12px;
}

.result_list .blw_content_wrap {
  padding: 10px 30px 20px 30px;
}
.result_list .result_item:hover {
  opacity: 0.8;
}
.result_list .result_item .result_item_img {
  width: 64px;
  height: 64px;
  border-radius: 4px;
  margin-right: 14px;
  border-radius: 4px;
}
.result_list .result_item .result_item_title {
  margin-bottom: 2px;
}
.result_list .result_item .result_item_price {
  margin-top: 6px;
}

.cancel_button {
  width: 100%;
  height: 40px;
  border: 1px #b4b4b4 solid;
  border-radius: 4px;
  color: white;
  background: #b4b4b4;
}

.cancel_button_active {
  width: 100%;
  height: 40px;
  border: 1px #132b4f solid;
  border-radius: 4px;
  background: white;
  color: #132b4f;
}

.cancel_button_complete {
  width: 100%;
  height: 40px;
  /* border: 1px black solid; */
  border-radius: 4px;
  background: rgba(124, 143, 172, 0.2);
  color: #7c8fac;
}

.disable_title_divider {
  height: 1px;
  background: rgba(231, 236, 244, 1);
}

.enable_title_divider {
  height: 2px;
  background: rgba(30, 100, 255, 1);
}

@media screen and (max-width: 992px) {
  .head_2 {
    font-size: 28px;
    line-height: 40px;
    font-weight: 800;
  }

  #pc_history .sub_title {
    font-size: 20px;
    line-height: 28px;
  }
  #pc_history .blw_title_bar {
    padding: 8px 14px 8px 14px;
  }

  .result_list .blw_content_wrap {
    padding: 10px 14px 20px 14px;
  }

  #pc_history .result_container {
    margin-bottom: 100px;
  }

  #pc_history .title_divider {
    height: 1px;
    width: 100%;
    background: #c1c8d4;
    margin-top: 10px;
  }

  #pc_history .item_divider {
    height: 1px;
    width: 100%;
    background: #e7ecf4;
    margin-top: 4px;
    margin-bottom: 6px;
  }
}
</style>
