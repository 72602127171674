<script setup>
import router from "@/router";
import { computed } from "vue";

const isShow = defineModel('isShow', {
  type: Boolean,
  required: true,
});


const emit = defineEmits(["closeAlert"]);

function onClickCloseButton() {
  emit("closeAlert");
}

function onClickLoginButton() {
  emit("closeAlert");
  router.push("/login");
}

function onClickSignupButton() {
  emit("closeAlert");
  router.push("/auth");
}
</script>

<template>
  <v-dialog
    v-model="isShow"
    @click:outside="onClickCloseButton"
    class="alert_wrap"
  >
    <v-card class="alert_container pa-0 ma-0" elevation="0">
      <v-container class="alert_body">
        <div class="alert_head">
          <button @click="onClickCloseButton" class="close_button">
            <img src="@/assets/icon/ic_x_blue_grey.svg" alt="닫기" />
          </button>
        </div>
        <div class="alert_content flex flex_col justify_center align_center">
          <img src="@/assets/icon/ic_warning.svg" />
          <span class="subtitle_5 color_secondary center">
            로그인이 필요한 서비스입니다.
          </span>
          <span class="body_2 color_caption center">
            로그인 또는 회원가입 페이지로 이동합니다.
          </span>
        </div>
        <button
          @click="onClickLoginButton"
          class="login_button bg_primary g0 subtitle_7"
        >
          로그인
        </button>
        <button
          @click="onClickSignupButton"
          class="signup_button bg_primary g0 subtitle_7"
        >
          회원가입
        </button>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.alert_body {
  padding: 20px;
}
.alert_head {
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: right;
  align-items: center;
  background: #fff;
  z-index: 200;
}

.alert_head .close_button {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.alert_content {
  margin-top: 10px;
  margin-bottom: 30px;
}

.alert_content img {
  width: 42px;
  height: 42px;
}

.alert_content span {
  margin-top: 10px;
}

.login_button {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  margin-bottom: 6px;
}

.signup_button {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: #132b4f 1px solid;
  background-color: white;
  color: #132b4f;
}

/* .v-overlay--active ::v-deep .v-overlay__scrim {
  display: none;
} */
</style>
