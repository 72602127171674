import {createRouter, createWebHistory} from "vue-router";
import {useLayoutStore} from "@/stores/layoutStore";
import {useUserStore} from "@/stores/userStore";
import MainPage from "@/views/MainPage.vue";
import LoginPage from "@/views/auth/LoginPage.vue";
import SignupPage from "@/views/auth/SignupPage.vue";
import SignupRegisterPage from "@/views/auth/SignupRegisterPage.vue";
import BlwSelectDatePage from "@/views/BlwRequest/BlwSelectDatePage.vue";
import BlwInputDataPage from "@/views/BlwRequest/BlwInputDataPage.vue";
import BlwItemSelectPage from "@/views/BlwRequest/BlwSelectItemPage.vue";
import BlwRequestCompletePage from "@/views/BlwRequest/BlwRequestCompletePage.vue";
import MyProfilePage from "@/views/My/MyProfilePage.vue";
import MyHistoryPage from "@/views/My/MyHistoryPage.vue";
import MyHistoryDetailPage from "@/views/My/MyHistoryDetailPage.vue";
import QuickQuotePage from "@/views/QuickQuotePage.vue";
import MyWithdrawalPage from "@/views/My/MyWithdrawalPage.vue";
import AuthPage from "@/views/auth/AuthPage.vue";
import NotFoundPage from "@/views/NotFoundPage.vue";
import FullLayout from "@/layouts/FullLayout.vue";
import PasswordResetAuthPage from "@/views/auth/PasswordResetAuthPage.vue";
import PasswordResetPage from "@/views/auth/PasswordResetPage.vue";
import MobileBlwSelectDatePage from "@/views/mobile/MobileBlwSelectDatePage.vue";
import MobileBlwInputDataPage from "@/views/mobile/MobileBlwInputDataPage.vue";
import MobileBlwSelectItemPage from "@/views/mobile/MobileBlwSelectItemPage.vue";
import MobileBlwSearchItemPage from "@/views/mobile/MobileBlwSearchItemPage.vue";
import MobileBlwCheckInfoPage from "@/views/mobile/MobileBlwCheckInfoPage.vue";
import MobileBlwRequestCompletePageVue from "@/views/mobile/MobileBlwRequestCompletePage.vue";
import MobileUserRoutingPage from "@/views/mobile/MobileMyMenuPage.vue";
import CoffeeAppLayout from "@/layouts/CoffeeAppLayout.vue";
import IndexPage from "@/views/coffee/IndexPage.vue";
import RequestRegisterView from "@/views/coffee/RequestRegisterView.vue";
import RequestCollectionView from "@/views/coffee/RequestCollectionView.vue";
import ListCollectionView from "@/views/coffee/ListCollectionView.vue";
import CollectionDetailView from "@/views/coffee/CollectionDetailView.vue";
import CoffeeMenuView from "@/views/coffee/MenuView.vue";
import {onBeforeMount} from "vue";
import axiosServices from "@/utils/axios/axios.js";
import RequestError from "@/views/coffee/RequestError.vue";
import {checkDisposeAvailable} from "@/api/coffee.js";

const {VITE_ENV} = import.meta.env;

const router = createRouter({
    // 스크롤 위치 초기화
    scrollBehavior() {
        return {top: 0};
    },
    history: createWebHistory(),
    routes: [
        {
            path: "/",
            components: {
                default: FullLayout,
                mobile: FullLayout,
            },
            children: [
                {
                    path: "",
                    name: "home",
                    components: {
                        default: MainPage,
                        mobile: MainPage,
                    },
                },
                {
                    path: "/login",
                    name: "login",
                    components: {
                        default: LoginPage,
                        mobile: LoginPage,
                    },
                },
                // {
                //   path: "/signup",
                //   name: "signup",
                //   component: SignupPage,
                // },
                {
                    path: "/signup-register",
                    name: "signup-register",
                    components: {
                        default: SignupRegisterPage,
                        mobile: SignupRegisterPage,
                    },
                },
                {
                    path: "/password-auth",
                    name: "password-auth",
                    components: {
                        default: PasswordResetAuthPage,
                        mobile: PasswordResetAuthPage,
                    },
                },
                {
                    path: "/password-reset/:auth_id",
                    name: "password-reset",
                    components: {
                        default: PasswordResetPage,
                        mobile: PasswordResetPage,
                    },
                },
                {
                    path: "/blw-request01",
                    name: "blw-request01",
                    components: {
                        default: BlwSelectDatePage,
                        mobile: MobileBlwSelectDatePage,
                    },
                },
                {
                    path: "/blw-request02",
                    name: "blw-request02",
                    components: {
                        default: BlwInputDataPage,
                        mobile: MobileBlwInputDataPage,
                    },
                },
                {
                    path: "/blw-request03",
                    name: "blw-request03",
                    components: {
                        default: BlwItemSelectPage,
                        mobile: MobileBlwSelectItemPage,
                    },
                },
                {
                    path: "/blw-search-item",
                    name: "blw-search-item",
                    components: {
                        default: MobileBlwSearchItemPage,
                        mobile: MobileBlwSearchItemPage,
                    },
                },
                {
                    path: "/blw-check-info",
                    name: "blw-check-info",
                    components: {
                        default: MobileBlwCheckInfoPage,
                        mobile: MobileBlwCheckInfoPage,
                    },
                },
                {
                    path: "/blw-complete",
                    name: "blw-complete",
                    components: {
                        default: BlwRequestCompletePage,
                        mobile: MobileBlwRequestCompletePageVue,
                    },
                },
                {
                    path: "/my/menu",
                    name: "my-menu",
                    components: {
                        default: MobileUserRoutingPage,
                        mobile: MobileUserRoutingPage,
                    },
                },
                {
                    path: "/my/profile",
                    name: "my-profile",
                    components: {
                        default: MyProfilePage,
                        mobile: MyProfilePage,
                    },
                },
                {
                    path: "/my/history",
                    name: "my-history",
                    components: {
                        default: MyHistoryPage,
                        mobile: MyHistoryPage,
                    },
                },
                // {
                //   path: "/my/history/detail",
                //   name: "my-history-detail",
                //   component: MyHistoryDetailPage,
                // },
                // {
                //   path: "/my/withdrawal",
                //   name: "my-withdrawal",
                //   component: MyWithdrawalPage,
                // },
                {
                    path: "/auth",
                    name: "auth",
                    components: {
                        default: AuthPage,
                        mobile: AuthPage,
                    },
                },
                {                    
                    path: "/quick-quote-default",
                    name: "quick-quote-default",
                    components: {
                        default: QuickQuotePage,
                        mobile: QuickQuotePage,
                    },
                },
            ],
        },
        {
            path: "/coffee",
            components: {default: CoffeeAppLayout, mobile: CoffeeAppLayout},
            beforeEnter: (to, from, next) => {                
                import('@/assets/coffee.css').then(() => {
                    next();
                });
            },
            children: [
                {
                    path: "/coffee",
                    name: "coffee-index",
                    components: {default: IndexPage, mobile: IndexPage},
                    meta: {showFooter: true, needLogin: true}
                },
                {
                    path: "/coffee/request/register",
                    name: "coffee-request-register",
                    components: {default: RequestRegisterView, mobile: RequestRegisterView},
                    meta: {showFooter: false}
                },
                {
                    path: "/coffee/request/collection",
                    name: "coffee-request-collection",
                    components: {default: RequestCollectionView, mobile: RequestCollectionView},
                    meta: {showFooter: false},
                    beforeEnter: async (to, from, next) => {
                        try {
                            const responseData = await checkDisposeAvailable()
                            if (responseData.is_restricted === true) {
                                next({name: 'coffee-collection-error'})
                            }
                        } catch (error) {
                            next({name: 'coffee-collection-error'})
                        }
                        next()
                    }
                },
                {
                    path: "/coffee/collection",
                    name: "coffee-list-collection",
                    components: {default: ListCollectionView, mobile: ListCollectionView},
                    meta: {showFooter: true}
                },
                {
                    path: "/coffee/collection/error",
                    name: "coffee-collection-error",
                    components: {default: RequestError, mobile: RequestError},
                    meta: {showFooter: true}
                },
                {
                    path: "/coffee/collection/:id",
                    name: "coffee-collection-detail",
                    components: {default: CollectionDetailView, mobile: CollectionDetailView},
                    meta: {showFooter: false}
                },
                {
                    path: "/coffee/menu",
                    name: "coffee-menu",
                    components: {default: CoffeeMenuView, mobile: CoffeeMenuView},
                    meta: {showFooter: true}
                },
            ],
            meta: {
                needAuthenticationGuard: true, // 네비게이션 가드 검증이 필요한 경우
                needLogin: true, // 로그인이 필요한 경우
            }
        },
        {
            path: "/quick-quote",
            name: "quick-quote",
            components: {
                default: QuickQuotePage,
                mobile: QuickQuotePage,
            },
        },
        {
            path: "/:pathMatch(.*)*",
            components: {
                default: NotFoundPage,
                mobile: NotFoundPage,
            },
        },
    ],
});


router.beforeEach((to, from, next) => {
    // console.log('userStore.isLogin() ', userStore.isLogin())
    // 대생폐 접수 특이 케이스
    if (VITE_ENV !== "DEV") {
        if (to.path === "/blw-request02" || to.path === "/blw-request03") {
            if (
                !(
                    from.path.startsWith("/blw-request") ||
                    from.path.startsWith("/blw-search-item")
                )
            ) {
                throw {nextRoute: "/", message: "잘못된 접근입니다."};
            }
        }
    }

    const userStore = useUserStore();
    const isLoggedIn = userStore.isLogin()
    if (isLoggedIn) {
        if (
            to.path === "/signup-register" ||
            to.path === "/auth" ||
            to.path === "/login"
        ) {
            throw {nextRoute: "/", message: "잘못된 접근입니다."};
        }
    }
    console.log('isLoggedIn ', isLoggedIn)
    // 각 route의 meta에 needAuthenticationGuard가 true일 경우
    // needLogin이 true일 경우 로그인이 되어있지 않으면 로그인 페이지로 이동
    // if (to?.meta?.needAuthenticationGuard === true) {
    //   if (to.meta.needLogin === true && !isLoggedIn) {
    //     throw { nextRoute: "/login", message: "로그인이 필요합니다." };
    //   } else if (to.meta.needLogin === false && isLoggedIn) {
    //     throw { nextRoute: "/", message: "잘못된 접근입니다." };
    //   }
    // }
    if (to?.meta?.needAuthenticationGuard === true) {
        if (to.meta.needLogin === true && userStore.isLogin() === null) {
            throw {nextRoute: "/login", message: "로그인이 필요합니다."};
        } else if (to.meta.needLogin === false && userStore.isLogin() !== null) {
            throw {nextRoute: "/", message: "잘못된 접근입니다."};
        }
    }

    // if (
    //   from.path.startsWith("/blw-check-info") &&
    //   (to.path !== "/" || to.path !== "/blw-complete")
    // ) {
    //   throw { nextRoute: "/", message: "잘못된 접근입니다." };
    // }

    next();
});

router.onError((error) => {
    const layoutStore = useLayoutStore();
    if (error.nextRoute) {
        layoutStore.showErrorPopup(error.message);
        router.push(error.nextRoute);
    } else {
        layoutStore.showErrorPopup("화면전환 중 알수 없는 에러가 발생했습니다.");
        router.push("/");
    }
});


export default router;
