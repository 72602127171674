<script setup>
import { ref, onMounted } from "vue";
import { useUserStore } from "@/stores/userStore";
import router from "@/router";
import WithdrawalCheckPopup from "@/components/Popup/WithdrawalCheckPopup.vue";
import { PasswordResetType } from "@/type/passwordResetType"

const userStore = useUserStore();

const showWithdrawPopup = ref(false);
const onClickShowWithdrawPopup = () => {
  showWithdrawPopup.value = true;
};

const onClickHideWithdrawPopup = () => {
  showWithdrawPopup.value = false;
};

const userNameField = ref(userStore.loginUserName);
const userCellphoneField = ref(userStore.loginUserPhoneNumber);

// function
function routerToLogin() {
  router.push("/login");
}

function routerToHistory() {
  router.replace("/my/history");
}

function routerPasswordAuth() {
  router.push({
    name: "password-auth", 
    params: {
      type: PasswordResetType.reset
    }
  });
}

// MARK: event
function onClickHistoryButton() {
  routerToHistory();
}

function onClickWithdrawalButton() {
  onClickShowWithdrawPopup();
}

function onClickResetPasswordButton() {
  routerPasswordAuth();
}

// life cycle

onMounted(() => {
  const access = userStore.access;
  const userId = userStore.loginUserId;
  if (access === null || userId === null || userId === "") {
    alert("로그인 이후 사용할수 있는 기능 입니다.");
    routerToLogin();
  }
});
</script>

<template>
  <div id="pc_profile">
    <withdrawal-check-popup
      :is-show="showWithdrawPopup"
      @close-alert="onClickHideWithdrawPopup"
    ></withdrawal-check-popup>
    <!-- pc_profile -->
    <div class="page_container flex flex_col justify_start align_center">
      <div
        class="single-content-page-wrap flex flex_col justify_center align_start"
      >
        <!-- 제목 Tab -->
        <div class="title_wrap flex flex_row justify_start align_center">
          <span class="head_2 black"> 마이페이지 </span>
        </div>
        <!-- <div class="title_wrap flex flex_row justify_start align_center">
          <button class="text_50 extra_bold left page_title">내 정보</button>
          <button
            @click="onClickHistoryButton"
            class="text_50 extra_bold left page_title gray4"
          >
            신청내역
          </button>
        </div> -->

        <div class="sub_title extra_bold black3">내정보</div>
        <div class="title_divider mb-10"></div>

        <!-- 이름 -->
        <div class="input_row">
          <div class="input_wrap2 flex flex_row justify_center align_end">
            <div class="grow flex flex_col justify_start align_stretch">
              <label for="name" class="label_2 input_label">이름</label>
              <input
                class="input body_3"
                type="text"
                name="name"
                id="name"
                disabled
                v-model="userNameField"
              />
            </div>
          </div>
        </div>
        <!-- 비밀번호 -->
        <!-- <div class="input_row">
          <div class="input_wrap flex flex_row justify_center align_end">
            <div class="grow flex flex_col justify_start align_stretch">
              <label for="password" class="input_label">비밀번호</label>
              <input
                class="input"
                type="password"
                name="password"
                id="password"
                value="123456789"
                placeholder="비밀번호 8~20자(영문, 숫자, 특수문자 중 2가지 포함)"
                disabled
              />
            </div>
            <button class="line_text_button">비밀번호 변경</button>
          </div>
        </div> -->
        <!-- 휴대폰 번호 -->
        <div class="input_row">
          <div class="input_wrap2 flex flex_row justify_center align_end">
            <div class="grow flex flex_col justify_start align_stretch">
              <label for="phoneNumber" class="label_2 input_label"
                >휴대폰 번호</label
              >
              <input
                class="input body_3"
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                placeholder="휴대폰 번호(-없이 입력)"
                v-model="userCellphoneField"
                disabled
              />
            </div>
            <!-- <button class="line_text_button">휴대폰 번호 변경</button> -->
          </div>
        </div>

        <!-- 마케팅 수신 동의 -->
        <!-- <div class="input_row check_box_row">
          <div class="input_wrap flex flex_row justify_start align_center">
            <label for="marketing" class="chk_box">
              <input type="checkbox" id="marketing" />
              <span class="on"></span>
              <span class="text_14 blue_grey flex align_center">
                [필수] 마케팅 수신 동의
                <img
                  class="tag_arrow_right"
                  src="@/assets/icon/ic_arrow_right_gray.svg"
                  alt=">"
                />
              </span>
            </label>
          </div>
        </div> -->

        <!-- <div class="button_wrap flex flex_row justify_center align_center">
          <button
            id="edit_button"
            class="grow full_button bg_primary white text_18 semi_bold"
          >
            수정하기
          </button>
          <button
            id="back_button"
            class="grow full_button bg_white black2 text_18 semi_bold"
          >
            돌아가기
          </button>
        </div> -->

        <div class="flex flex_row">
          <button
            @click="onClickWithdrawalButton"
            class="body_2 color_caption withdrawal_button"
          >
            회원탈퇴
          </button>
          <button
            @click="onClickResetPasswordButton"
            class="body_2 color_caption withdrawal_button"
          >
            비밀번호 재설정
          </button>
        </div>        
      </div>
    </div>
  </div>
</template>

<style scoped>
#pc_profile .title_wrap,
#pc_profile_edit .title_wrap {
  gap: 30px;
  margin-bottom: 60px;
}

#pc_profile .input_row,
#pc_profile_edit .input_row {
  width: 100%;
  margin-bottom: 30px;
}

#pc_profile .sub_title {
  font-size: 28px;
  line-height: 40px;
}

#pc_profile .title_divider {
  height: 1px;
  width: 100%;
  background: #e7ecf4;
  margin-top: 10px;
}

#pc_profile .input {
  border: 1px #c1c8d4 solid;
  color: #7c8fac;
  background-color: rgba(193, 200, 212, 0.2);
}

#pc_profile .input_row.check_box_row,
#pc_profile_edit .input_row.check_box_row {
  padding-top: 30px;
  margin-bottom: 15px;
  border-top: 2px solid #ededed;
}

#pc_profile .button_wrap,
#pc_profile_edit .button_wrap {
  width: 100%;
  gap: 26px;
}

.button_wrap #edit_button {
  border: 1px solid #1e64ff;
}
.button_wrap #back_button {
  border: 1px solid #000000;
}

/* pc_profile_edit.html */
.cancel_button {
  color: #fff;
  background-color: #767676;
  border: none;
}
.cancel_button:hover {
  background-color: #5d5a5a;
}

.withdrawal_button {
  width: 110px;
  height: 40px;
}

@media screen and (max-width: 992px) {
  .head_2 {
    font-size: 28px;
    line-height: 40px;
  }

  #pc_profile .sub_title {
    font-size: 20px;
    line-height: 28px;
  }

  .withdrawal_button {
    width: 94px;
    margin-bottom: 100px;
  }
}
</style>
