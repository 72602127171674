<script setup>
import { ref } from "vue";
import router from "@/router";
import { useUserStore } from "@/stores/userStore";
import { useRequestStore } from "@/stores/requestStore";
import LoginGuidPopup from "@/components/Popup/LoginGuidPopup.vue";
import { openZguhadaBlog } from "@/utils/outsideLink";

const userStore = useUserStore();
const requestStore = useRequestStore();

// popup
const showLoginGuidePopup = ref(false);

const hideLoginGuidePopup = () => {
  showLoginGuidePopup.value = false;
};

function onClickSignupButton() {
  router.push("/auth");
}

function onClickRouteLoginButton() {
  router.push("/login");
}

function onClickRouteBlwRequestButton() {
  if (userStore.isLogin()) {
    requestStore.resetAllData();
    router.push("/blw-request01");
  } else {
    showLoginGuidePopup.value = true;
  }
}

function onClickMyPageButton() {
  router.push("/my/history");
}

function onClickLogoutButton() {
  userStore.logout();
}

function clickAppstore() {
  window.open("https://apps.apple.com/app/id6459451210", "_blank");
}

function clickPlaystore() {
  window.open(
    "https://play.google.com/store/apps/details?id=com.chunil.zguhada.platform.app.zguhada_platform",
    "_blank"
  );
}
</script>

<template>
  <div id="user_menu">
    <login-guid-popup
      :is-show="showLoginGuidePopup"
      @close-alert="hideLoginGuidePopup"
    ></login-guid-popup>
    <div class="page_container flex flex_col justify_start align_center">
      <div
        class="single-content-page-wrap flex flex_col justify_center align_start"
      >
        <div class="page_wrapper">
          <div v-if="userStore.isLogin()">
            <div class="flex flex_col justify_center align_start">
              <div style="word-wrap: break-word; width: 100%; flex-wrap: wrap">
                <span class="subtitle_5 color_primary">
                  {{ userStore.loginUserName }}
                  <span class="subtitle_5 color_blue_midnight"> &nbsp님 </span>
                </span>
              </div>

              <button
                @click="onClickMyPageButton"
                class="body_1"
                style="margin-top: 30px; padding: 10px 0"
              >
                마이페이지
              </button>

              <button
                @click="onClickLogoutButton"
                class="body_1"
                style="margin-top: 22px; padding: 10px 0"
              >
                로그아웃
              </button>
            </div>
          </div>
          <div
            v-else
            @click="onClickRouteLoginButton"
            class="login_guide_wrapper flex flex_row align_center"
          >
            <p class="body_1">로그인 후 서비스 이용하기</p>
            <img src="@/assets/icon/ic_arrow_right_black.svg" />
          </div>

          <button
            @click="onClickRouteBlwRequestButton"
            class="blw_routing_button label_1 g0"
            :style="
              userStore.isLogin() ? 'margin-top: 50px;' : 'margin-top: 116px;'
            "
          >
            대형생활폐기물 배출신고
          </button>

          <template v-if="!userStore.isLogin()">
            <div class="divider"></div>
            <div class="signup_guide_wrapper flex flex_row">
              <p class="subtitle_7 color_secondary">아직 회원이 아니신가요?</p>
              <button
                @click="onClickSignupButton"
                class="subtitle_7 color_primary text_underline"
              >
                회원가입하기
              </button>
            </div>
            <div
              class="login_issue_box flex flex_row align_center justify_start"
            >
              <img src="@/assets/icon/icon_question.svg" />
              <span class="caption_1 color_caption">
                로그인에 문제가 있나요? 1660-3114로 문의해 주세요.
              </span>
            </div>
          </template>
          <template v-else>
            <div class="title_divider" style="margin-top: 30px"></div>
            <div
              class="footer_btn_small_link_wrap flex flex_row justify_start align_start"
              :style="
                userStore.isLogin() ? 'margin-top: 120px;' : 'margin-top: 60px;'
              "
            >
              <button @click="openZguhadaBlog">
                <img
                  src="@/assets/image/img_btn_small_link_naver_blog.svg"
                  alt="naver_blog"
                />
              </button>
              <button v-on:click="clickPlaystore()">
                <img
                  src="@/assets/image/img_btn_small_link_playstore.svg"
                  alt="playstore"
                />
              </button>
              <button v-on:click="clickAppstore()">
                <img
                  src="@/assets/image/img_btn_small_link_appstore.svg"
                  alt="appstore"
                />
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.page_wrapper {
  margin-bottom: 60px;
  width: 100%;

  padding: 10px;
}

.login_guide_wrapper {
  width: 100%;
  gap: 7px;
}

.login_guide_wrapper img {
  width: 18px;
  height: 18px;
}

.blw_routing_button {
  background: #1e64ff;
  border-radius: 4px;
  width: 100%;
  height: 50px;

  margin-top: 116px;
}

.divider {
  height: 1px;
  width: 100%;
  background: #e7ecf4;
}

.signup_guide_wrapper {
  margin-top: 60px;
  gap: 4px;
}

.login_issue_box {
  margin-top: 28px;
  gap: 4px;
  width: 100%;
}

.login_issue_box img {
  width: 12px;
  height: 12px;
}

.footer_btn_small_link_wrap {
  margin-top: 60px;
}
</style>
