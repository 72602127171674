<script setup>
import { ref, computed } from "vue";
import { RouterView } from "vue-router";
import { useLayoutStore } from "@/stores/layoutStore";
import { useUserStore } from "@/stores/userStore";
import { useRequestStore } from "@/stores/requestStore";
import router from "@/router";

import LoginGuidPopup from "@/components/Popup/LoginGuidPopup.vue";
import FooterLayout from "@/layouts/FooterLayout.vue";
// icon
import logoPrimary from "@/assets/logo.svg";
import logoWhite from "@/assets/logo_white.svg";
import icMenuWhte from "@/assets/icon/ic_menu_white.svg";
import icMenuPrimary from "@/assets/icon/ic_menu_primary.svg";
import icXBlueGrey from "@/assets/icon/ic_x_blue_grey.svg";

const requestStore = useRequestStore();
const layoutStore = useLayoutStore();
const userStore = useUserStore();

// popup
const showLoginGuidePopup = ref(false);

const hideLoginGuidePopup = () => {
  showLoginGuidePopup.value = false;
};

const useCurrentPath = () => {
  const currentPath = router.currentRoute.value.path;

  return currentPath;
};

const isLoginPage = computed(() => {
  const currentPath = useCurrentPath();
  return (
    currentPath === "/login" ||
    currentPath.startsWith("/signup") ||
    currentPath === "/auth" ||
    currentPath.startsWith("/password")
  );
});

const isMainPage = computed(() => {
  const currentPath = useCurrentPath();
  return currentPath === "/";
});

const isMyPage = computed(() => {
  const currentPath = useCurrentPath();

  return currentPath.startsWith("/my");
});

/* only mobile */
const hideHeader = computed(() => {
  const currentPath = useCurrentPath();

  return currentPath === "/blw-search-item";
});

// MARK: mobile 헤더 세팅
const lightHeader = computed(() => {
  const currentPath = useCurrentPath();

  return !(currentPath === "/");
});

const lightHeaderLogoSrc = computed(() => {
  return lightHeader.value ? logoPrimary : logoWhite;
});

const lightHeaderMenuSrc = computed(() => {
  return lightHeader.value ? icMenuPrimary : icMenuWhte;
});

const hideFooter = computed(() => {
  const currentPath = useCurrentPath();

  return currentPath === "/my/menu" || currentPath === "/blw-search-item";
});

const showBlwHeaderButton = computed(() => {
  const currentPath = useCurrentPath();

  return (
    currentPath === "/" ||
    (currentPath.startsWith("/my") && currentPath !== "/my/menu")
  );
});

// function

function routeLogin() {
  router.push("/login");
}

function routeSignup() {
  router.push("/auth");
}

function onClickLogo() {
  router.push({ name: "home" });
}

function onClickLoginButton() {
  routeLogin();
}

function routeMyPage() {
  router.push("/my/history");
}

function routeMain() {
  router.replace("/");
}

function onClickSignupButton() {
  routeSignup();
}

function onClickMenuButton() {
  router.push("/my/menu");
}

function onClickLogoutButton() {
  userStore.logout();
}

function onClickRequestButton() {
  if (userStore.isLogin()) {
    requestStore.resetAllData();
    router.push("/blw-request01");
  } else {
    showLoginGuidePopup.value = true;
  }
}

function onClickMyPageButton() {
  routeMyPage();
}

function onClickCloseButton() {
  routeMain();
}

function onClickBlwRequestButton() {
  if (userStore.isLogin()) {
    requestStore.resetAllData();
    router.push("/blw-request01");
  } else {
    showLoginGuidePopup.value = true;
  }
}
</script>

<template>
  <v-locale-provider>
    <v-app>
      <!--      <Customizer />-->
      <v-main>
        <div>
          <login-guid-popup
            :is-show="showLoginGuidePopup"
            @close-alert="hideLoginGuidePopup"></login-guid-popup>
          <common-alert
            id="base_error_alert"
            :is-show="layoutStore.isShowErrorPopup"
            :title="layoutStore.errorMessage"
            :description="layoutStore.errorDescription"
            @okayAction="layoutStore.closeErrorPopup"
            @closeAlert="layoutStore.closeErrorPopup">
          </common-alert>
          <common-alert
            id="base-info-alert"
            :is-show="layoutStore.isShowInfoPopup"
            :title="layoutStore.infoMessage"
            :description="layoutStore.infoDescription"
            @okayAction="layoutStore.closeInfoPopup"
            @closeAlert="layoutStore.closeInfoPopup">
          </common-alert>
          <common-alert
            id="base-confirm-alert"
            :is-show="layoutStore.isShowConfrimPopup"
            :title="layoutStore.confirmMessage"
            :description="layoutStore.confirmDescription"
            :has-cancel="true"
            @okayAction="layoutStore.confirmOkayAction"
            @cancelAction="layoutStore.confirmCancelAction"
            @closeAlert="layoutStore.confirmCancelAction">
          </common-alert>
          <div v-if="layoutStore.onLoading" class="overlay">
            <div>
              <div class="spinner-border" style="" role="status">
                <v-progress-circular indeterminate></v-progress-circular>
              </div>
            </div>
          </div>
          <div>
            <header
              v-show="!hideHeader"
              class="flex flex-col justify_center align_center"
              :class="lightHeader ? 'light_header' : {}">
              <div class="header_wrap flex justify_space_between align_center">
                <!-- 로고 -->
                <img
                  @click="onClickLogo"
                  class="logo only_pc_block cusor_point"
                  src="../assets/logo.svg"
                  alt="지구하다 로고"
                  style="cursor: pointer" />
                <img
                  class="logo only_mo_block"
                  :src="lightHeaderLogoSrc"
                  alt="지구하다 로고"
                  @click="onClickLogo" />
                <!-- pc header -->
                <!-- 로그인, 신청하기 버튼 -->
                <div class="only_pc_block">
                  <div
                    class="flex justify_end align_center"
                    v-show="!isLoginPage">
                    <template v-if="isMainPage">
                      <div v-if="userStore.isLogin()">
                        <button
                          class="text_20 mid mr-10"
                          flat
                          @click="onClickMyPageButton">
                          마이페이지
                        </button>
                        <button
                          class="text_20 mid mr-10"
                          flat
                          @click="onClickLogoutButton">
                          로그아웃
                        </button>
                      </div>
                      <div class="flex justify_end align_center" v-else>
                        <button
                          @click="onClickSignupButton"
                          type="button"
                          class="header_button only_pc_block text_20 mid center">
                          회원가입
                        </button>
                        <button
                          @click="onClickLoginButton"
                          type="button"
                          class="header_button only_pc_block text_20 mid center">
                          로그인
                        </button>
                      </div>
                      <button
                        @click="onClickRequestButton"
                        type="button"
                        class="header_button only_pc_block text_20 bold center white bg_primary border_r100">
                        대형생활폐기물 배출신고
                      </button>
                      <!--                    <button type="button" class="menu_button only_mo_block">-->
                      <!--                      <img-->
                      <!--                        src="../assets/icon/ic_menu_black.png"-->
                      <!--                        alt="메뉴 아이콘"-->
                      <!--                      />-->
                      <!--                    </button>-->
                    </template>
                    <template v-else-if="isMyPage">
                      <div class="flex flex_row" v-if="userStore.isLogin()">
                        <button
                          class="text_20 bold primary mr-10"
                          flat
                          @click="onClickMyPageButton">
                          마이페이지
                        </button>
                        <button
                          class="text_20 mid mr-10"
                          flat
                          @click="onClickLogoutButton">
                          로그아웃
                        </button>
                        <button
                          @click="onClickRequestButton"
                          type="button"
                          class="header_button only_pc_block text_20 bold center white bg_primary border_r100">
                          대형생활폐기물 배출신고
                        </button>
                      </div>
                    </template>
                  </div>
                </div>

                <!-- mobile header -->
                <div class="only_mo_block">
                  <div class="mobile_header_button_wrap flex flex_row">
                    <button
                      v-show="showBlwHeaderButton"
                      class="blw_request_button subtitle_7"
                      @click="onClickBlwRequestButton">
                      대형생활폐기물 배출신고
                    </button>
                    <div class="flex justify_end align_center">
                      <button
                        v-if="useCurrentPath() === '/my/menu'"
                        @click="onClickCloseButton">
                        <img :src="icXBlueGrey" />
                      </button>
                      <button v-else @click="onClickMenuButton">
                        <img :src="lightHeaderMenuSrc" />
                      </button>
                    </div>
                  </div>
                </div>
                <!-- 헤더 네비 -->
                <!-- <div class="top_nav_list bg_white flex flex_col justify_start align_start">
            <button class="top_nav flex justify_start align_center">
                <img src="../assets/icon/ic_user.png" alt="내 정보" />
                <p class="text_14 mid left">내정보</p>
            </button>
            <button class="top_nav flex justify_start align_center">
                <img src="../assets/icon/ic_history.png" alt="신청 내역" />
                <p class="text_14 mid left">신청 내역</p>
            </button>
            <button class="top_nav flex justify_start align_center">
                <img src="../assets/icon/ic_logout.png" alt="로그아웃" />
                <p class="text_14 mid left">로그아웃</p>
            </button>
        </div> -->
              </div>
            </header>

            <!-- body -->
            <div
              class="body"
              :style="hideHeader ? { 'margin-top': '0px' } : {}">
              <router-view v-if="!useLayoutStore().isMobile" />
              <router-view v-else name="mobile" />

              <!-- footer -->
              <footer-layout v-show="!hideFooter" />
            </div>
          </div>
        </div>
      </v-main>
    </v-app>
  </v-locale-provider>
</template>

<style scoped>
.light_header {
  background: white;
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999;
  display: flex;
  justify-content: center; /* 가로 중앙 정렬 */
  align-items: center;
}

.mobile_header_button_wrap {
  gap: 16px;
}

.blw_request_button {
  background-color: #1e64ff;
  border-radius: 4px;
  color: white;
  height: 37px;
  padding: 0px 12px;
}

/* background: #1e23ff; */

@media screen and (max-width: 992px) {
  header {
    background-color: #123ba3;
    box-shadow: none;
  }

  header .menu_button img {
    width: 38px;
    height: 38px;
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
  }
}
</style>
