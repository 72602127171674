<script setup>
import { onMounted, ref, onUnmounted, computed } from "vue";
import router from "@/router";
import { putCellPhoneAuth, postCellphoneAuth } from "@/api/auth";
import {
  isNumericString,
  validatePhoneNumber,
  validateAuthCode,
} from "@/utils/utils";
import { useUserStore } from "@/stores/userStore";
import { useLayoutStore } from "@/stores/layoutStore";
import { CommonString } from "@/assets/global/property";

// icon
import passwordShowIcon from "@/assets/icon/ic_small_view.svg";
import passwordHiddenIcon from "@/assets/icon/ic_small_hidden.svg";

const TIMER_MAX_VALUE = 60 * 3;
const TIMER_MAX_LABEL_VALUE = "03:00";
const AUTHCODE_MAX_LENGTH = 6;

const userStore = useUserStore();
const layoutStore = useLayoutStore();

const { statePhoneNumber, statePhoneAuthId, stateUserId, stateAuthCode } =
  history.state;

const showAuthCodeInfoAlert = ref(false);
const showNotUserAlert = ref(false);

const notUserAlertOkayAction = () => {
  showNotUserAlert.value = false;
  routerRegister();
};

const notUserAlertCloseAction = () => {
  showNotUserAlert.value = false;
};

const phoneNumberField = ref("");
const authCodeField = ref("");
const passwordField = ref("");
const passwordShowField = ref(false);

const phoneAuthId = ref(null);
const userId = ref(null);
const isSendAuthCode = ref(false);

// timer
const timerCount = ref(TIMER_MAX_VALUE);
const timerLabelField = ref(TIMER_MAX_LABEL_VALUE);
const showTimerLabel = ref(false);
var authTimer = null;

const isPhoneFieldValid = computed(() => {
  return validatePhoneNumber(phoneNumberField.value);
});

const isAuthCodeValid = computed(() => {
  return validateAuthCode(authCodeField.value);
});

const isAuthcodeError = ref(false);
const isLoginError = ref(false);
const loginErrorMessage = ref("비밀번호가 일치하기 않습니다.");
const loginButtonActive = computed(() => {
  return (
    userId.value !== null &&
    userId.value != 0 &&
    phoneAuthId.value !== null &&
    passwordField.value.length > 0 &&
    !isLoginError.value
  );
});

// alert

function onClickOkayButton() {
  showAuthCodeInfoAlert.value = false;
}

function onClickCloseAlert() {
  showAuthCodeInfoAlert.value = false;
}

// function

function routerSignup() {
  router.push("/auth");
}

function routerFindPassword() {
  router.push("/password-auth")
}

function routerRegister() {
  router.push({
    name: "signup-register",
    state: {
      phoneNumber: phoneNumberField.value,
      phoneAuthId: phoneAuthId.value,
    },
  });
}

function timerStart() {
  timerCount.value = TIMER_MAX_VALUE;
  timerLabelField.value = TIMER_MAX_LABEL_VALUE;
  showTimerLabel.value = true;

  if (authTimer !== null) {
    clearInterval(authTimer);
  }

  authTimer = setInterval(() => {
    timerCount.value--;
    timerLabelField.value = getTimerLabelValue();
    if (timerCount.value < 0) timerStop(true);
  }, 1000);
}

function timerStop(isEnd) {
  if (authTimer !== null) {
    clearInterval(authTimer);
    timerCount.value = 0;
    timerLabelField.value = "00:00";

    if (isEnd) {
      timerCount.value = 0;
    } else {
      timerCount.value = TIMER_MAX_VALUE;
    }
  }
}

function getTimerLabelValue() {
  let time = timerCount.value / 60;
  let minutes = parseInt(time);
  let secondes = Math.round((time - minutes) * 60);

  return (
    minutes.toString().padStart(2, "0") +
    ":" +
    secondes.toString().padStart(2, "0")
  );
}

function onClickSendAuthCode() {
  const phoneNumber = phoneNumberField.value;

  if (phoneNumber === "") {
    alert("전화번호를 입력해야합니다.");
    return;
  } else if (!isNumericString(phoneNumber)) {
    alert("전화번호는 숫자만 입력하셔야 합니다.");
    return;
  }

  phoneAuthId.value = null;
  userId.value = null;

  requestPutAuthCode(phoneNumber);
}

function onClickCheckAuthCode() {
  const authId = phoneAuthId.value;
  const authCode = authCodeField.value;

  if (authCode.length < 6) {
    alert("인증번호를 입력해주세요.");
    return;
  }

  requestPostCellphoneAuth(authId, authCode);
}

function onClickPasswordShowButton() {
  passwordShowField.value = !passwordShowField.value;
}

function onClickShowAuthInfoAlert() {
  showAuthCodeInfoAlert.value = true;
}

function onClickLogin() {
  const authId = phoneAuthId.value;
  const userIdField = userId.value;
  const password = passwordField.value;

  if (password === "") {
    alert("비밀번호를 입력하셔야 합니다.");
    return;
  } else if (userIdField === null || authId === null) {
    alert("휴대폰번호 인증을 하셔야합니다.");
    return;
  }

  requestPostLogin(userIdField, password, authId);
}

function onClickGoSignup() {
  routerSignup();
}

function onClickRouteFindPassword() {
  routerFindPassword();
}

// api request
async function requestPutAuthCode(phoneNumber) {
  try {
    const response = await putCellPhoneAuth(phoneNumber);

    phoneAuthId.value = response.id;
    isSendAuthCode.value = true;
    authCodeField.value = "";
    isAuthcodeError.value = false;
    isLoginError.value = false;

    timerStart();
  } catch (error) {
    if (error.response.data.message) {
      alert(error.response.data.message);
    }
    isAuthcodeError.value = true;
  }
}

async function requestPostCellphoneAuth(id, authNo) {
  try {
    const response = await postCellphoneAuth(id, authNo);

    const isNewUser = response.is_new_user;

    showTimerLabel.value = false;
    isAuthcodeError.value = false;
    timerStop(false);

    if (isNewUser) {
      userId.value = 0;
      showNotUserAlert.value = true;
    } else {
      userId.value = response.user_id;
    }
  } catch (error) {
    isAuthcodeError.value = true;
  }
}

async function requestPostLogin(userId, password, phoneAuthId) {
  try {
    await userStore.login(userId, password, phoneAuthId);
  } catch (error) {
    loginErrorMessage.value = error.response.data.message;
    isLoginError.value = true;
  }
}

// life cycle
function onChangePasswordField() {
  isLoginError.value = false;
}

onMounted(() => {
  if (
    statePhoneNumber !== undefined &&
    statePhoneAuthId !== undefined &&
    stateUserId !== undefined &&
    stateAuthCode !== undefined
  ) {
    phoneNumberField.value = statePhoneNumber;
    phoneAuthId.value = statePhoneAuthId;
    isSendAuthCode.value = true;
    userId.value = stateUserId;
    authCodeField.value = stateAuthCode;
  }
});

onUnmounted(() => {
  if (authTimer !== null) {
    clearInterval(authTimer);
  }
});
</script>

<template>
  <common-alert
    id="authCodeInfoAlert"
    :is-show="showAuthCodeInfoAlert"
    :title="CommonString.AuthCodeInfoTitle"
    :description="CommonString.AuthCodeInfoDescription"
    @okayAction="onClickOkayButton"
    @closeAlert="onClickCloseAlert"></common-alert>
  <common-alert
    id="notUserAlert"
    :is-show="showNotUserAlert"
    title="가입된 정보가 없습니다."
    description="회원가입 페이지로 이동할까요?"
    @okayAction="notUserAlertOkayAction"
    @closeAlert="notUserAlertCloseAction"></common-alert>
  <div id="login">
    <div class="page_container flex flex_col justify_start align_center">
      <div
        class="single-content-page-wrap flex flex_col justify_center align_start">
        <p class="head_2 left page_title">로그인</p>
        <span class="page_subtitle subtitle_7 color_secondary">
          아직 회원이 아니신가요?
          <span
            @click="onClickGoSignup"
            class="subtitle_7 color_primary text_underline"
            style="cursor: pointer">
            회원가입하기
          </span>
        </span>

        <!-- 휴대폰 번호 -->
        <div class="input_row">
          <div class="input_wrap2 flex flex_row justify_start align_end">
            <div class="grow flex flex_col justify_start align_stretch">
              <label for="phoneNumber" class="label_2 mb-1">
                휴대전화 번호
              </label>
              <input
                class="input input-field body_2"
                :class="{
                  is_error: !isPhoneFieldValid && phoneNumberField.length > 0,
                }"
                v-model="phoneNumberField"
                type="tel"
                name="phoneNumber"
                id="phoneNumber"
                placeholder="휴대폰 번호(-없이 입력)" />
            </div>
            <button
              @click="onClickSendAuthCode"
              class="action_button body_2"
              :class="isSendAuthCode ? 'action_button2' : 'action_button'"
              :disabled="!isPhoneFieldValid">
              {{ isSendAuthCode ? "인증번호 재전송" : "인증번호 전송" }}
            </button>
            <p
              class="error_message body_4 color_warning left"
              v-show="!isPhoneFieldValid && phoneNumberField.length > 0">
              휴대전화 번호를 확인해 주세요.
            </p>
          </div>
        </div>

        <!-- 인증 번호 -->
        <div class="input_row" style="margin-bottom: 0">
          <div class="input_wrap2 flex flex_row justify_center align_end">
            <div class="grow flex flex_col justify_start align_stretch">
              <label for="number" class="label_2 mb-1">인증 번호</label>
              <div class="input-container">
                <input
                  class="input input-field body_2"
                  :class="{
                    is_error:
                      (!isAuthCodeValid && authCodeField.length > 0) ||
                      isAuthcodeError ||
                      timerCount === 0,
                  }"
                  v-model="authCodeField"
                  :disabled="userId !== null"
                  inputmode="numeric"
                  name="number"
                  id="number"
                  step="0"
                  placeholder="6자리 입력"
                  :maxlength="AUTHCODE_MAX_LENGTH"
                  autocomplete="one-time-code" />
                <label
                  class="input-timer-label color_warning body_4"
                  v-show="showTimerLabel">
                  {{ timerLabelField }}</label
                >
              </div>
            </div>
            <button
              @click="onClickCheckAuthCode"
              class="action_button body_2"
              :disabled="
                userId !== null || timerCount === 0 || !isAuthCodeValid
              ">
              확인
            </button>
          </div>
          <div
            style="height: 14px"
            v-bind:class="{
              hidden: !(
                (!isAuthCodeValid && authCodeField.length > 0) ||
                isAuthcodeError ||
                timerCount === 0
              ),
            }">
            <span
              class="body_4 color_warning left"
              v-if="timerCount === 0"
              style="margin-top: 2px">
              입력 시간이 초과되었습니다. 인증번호 전송 버튼을 다시 눌러주세요.
            </span>
            <p
              class="body_4 color_warning left"
              v-else-if="
                (!isAuthCodeValid && authCodeField.length > 0) ||
                isAuthcodeError
              "
              style="margin-top: 2px">
              인증번호를 확인해주세요.
            </p>
          </div>
        </div>
        <span
          @click="onClickShowAuthInfoAlert"
          class="subtitle_7 color_primary text_underline"
          style="margin: 20px 0px; cursor: pointer">
          인증번호를 받지 못하셨나요?
        </span>

        <!-- 비밀번호 -->
        <div class="input_row">
          <div
            class="input_wrap2 password_input flex flex_row justify_center align_end">
            <div class="grow flex flex_col justify_start align_stretch">
              <label for="password" class="label_2 mb-1">비밀번호</label>
              <input
                class="input body_2"
                :class="{ is_error: isLoginError }"
                v-model="passwordField"
                :type="passwordShowField ? 'text' : 'password'"
                name="password"
                id="password"
                placeholder="비밀번호 입력"
                @input="onChangePasswordField" />
              <button
                class="view_password"
                @click="onClickPasswordShowButton()">
                <img
                  :src="
                    passwordShowField ? passwordShowIcon : passwordHiddenIcon
                  "
                  alt="비밀번호 보기" />
              </button>
              <p
                class="error_message body_4 color_warning left"
                style="margin-top: 2px"
                v-show="isLoginError">
                {{ loginErrorMessage }}
              </p>
            </div>
          </div>
        </div>

        <div class="button_wrap flex flex_col justify_center align_center">
          <button
            @click="onClickLogin"
            class="login_button label_1"
            :disabled="!loginButtonActive">
            로그인
          </button>
          <div class="login_guide_box">            
            <span class="subtitle_7 color_secondary">
              비밀번호를 잊어버리셨나요?
              <span
                @click="onClickRouteFindPassword"
                class="subtitle_7 color_primary text_underline"
                style="cursor: pointer">
                비밀번호 찾기
              </span>
            </span>
          </div>
        </div>

        <div class="login_issue_box flex flex_row align_center justify_center">
          <img src="@/assets/icon/icon_question.svg" />
          <span class="caption_1 color_caption">
            로그인에 문제가 있나요? 1660-3114로 문의해 주세요.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#login .input_row {
  width: 100%;
  margin-bottom: 30px;
}

#login .button_wrap {
  width: 100%;
  padding-top: 20px;
  gap: 10px;
}

#login .input::placeholder {
  color: #c1c8d4;
  font-size: 14px;
  line-height: 21px;
}

.single-content-page-wrap .page_subtitle {
  margin-bottom: 65px;
}
.action_button {
  height: 40px;
  width: 110px;
  background-color: #132b4f;
  color: white;
  border-radius: 4px;
}
.action_button2 {
  height: 40px;
  width: 110px;
  background-color: white;
  color: #132b4f;
  border: #132b4f 1px solid;
  border-radius: 4px;
}

.action_button2:disabled {
  height: 40px;
  width: 110px;
  background-color: #ededed;
  border-radius: 4px;
  border: none;
  color: #7a7a7a;
}
.action_button:disabled {
  height: 40px;
  width: 110px;
  background-color: #ededed;
  border-radius: 4px;
  color: #7a7a7a;
}

.login_button {
  width: 100%;
  height: 50px;
  background-color: #1e64ff;
  color: white;
  border-radius: 4px;
}
.login_button:disabled {
  width: 100%;
  height: 50px;
  background-color: #ededed;
  color: #7a7a7a;
  border-radius: 4px;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.input-field {
  width: 100%;
  padding-right: 50px;
}

.input-timer-label {
  position: absolute;
  right: 10px;
  background-color: transparent;
  /* pointer-events: none; */
}

.login_issue_box {
  margin-top: 58px;
  width: 100%;
}

.login_issue_box img {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}

.login_guide_box {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 30px;
}

/* mobile */
@media screen and (max-width: 992px) {
  .single-content-page-wrap .page_subtitle {
    margin-bottom: 33px;
  }

  #login .head_2 {
    font-size: 28px;
    line-height: 40px;
    font-weight: 800;
  }

  #login .action_button {
    width: 98px;
  }

  #login .button_wrap {
    padding-top: 30px;
  }

  .login_issue_box {
    margin-top: 38px;
    margin-bottom: 60px;
  }

  .login_guide_box {
    display: flex;
    flex-direction: column;
    /* align-self: flex-start; */
    text-align: center;
    gap: 4px;
    margin-top: 40px;
  }
}
</style>
